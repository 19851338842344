export default {
    breakpoints: [
      '40em', '52em', '64em',
    ],
    colors: {
      text: '#000',
      background: '#fff',
      primary: 'white',
      secondary: 'black',
    },
    buttons: {
      primary: {
        color: 'black',
        bg: 'transparent',
        borderColor: 'black',
        border: 2,
        borderRadius: 0,
      },
      secondary: {
        color: 'white',
        bg: 'secondary',
        borderRadius: 0,
      },
      outline: {
        color: 'black',
        bg: 'white',
        border: 'black',
        borderRadius: 0,
      },
      transparent: {
        bg: 'transparent'
      },
      subscribe: {
        border: '1px solid red'
      }
    }
  }